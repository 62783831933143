<template>
  <div>
    <purchaseModal
        v-if="showModal"
        @showModal = 'changeShowModal'
        :purchase = purchase
        @search = 'search'
    />
    <purchase-history-modal
        v-if="showHistoryModal && purchase.histories.length"
        @showModal = 'changeShowHistoryModal'
        :purchase = purchase
        @search = 'search'
    />
    <p v-if="rows && rows.meta" class="pagination__text">
      {{ rows.meta.from }}-{{ rows.meta.to }} из
      {{ rows.meta.total }}
    </p>
    <b-pagination
        v-model="currentPage"
        v-if="rows && rows.meta"
        :total-rows="rows.meta.total"
        :per-page="perPage"
        aria-controls="my-table"
        :hide-goto-end-buttons="true"
        :limit="1"
        class="pagination"
        @input="changePage()"
    >
      <template #prev-text>
        <img
            class="pagination__btn-icon pagination__btn-icon_prev"
            src="@/assets/png/database-icon-active.png"
            alt=""
        />
      </template>
      <template #next-text>
        <img class="pagination__btn-icon" src="@/assets/png/database-icon-active.png" alt="" />
      </template>
    </b-pagination>

    <div class="table">
      <b-table
          v-if="rows"
          id="my-table"
          class="table__box"
          :borderless="true"
          :items="rows.data"
          :fields="fields"
          small
      >
        <template #cell(id)="data">
          <a @click="changeShowModal(data.item)" style="color: #0366D6">
            {{ data.item.id }}
          </a>
        </template>
        <template #cell(status)="data">
          <span :class="statuses[data.item.status]">
            {{ status(data.item.status) }}
          </span>
        </template>
        <template #cell(contractor)="data">
          <span >
            {{ data.item.contractor?.name ?? '' }}
          </span>
        </template>
        <template #cell(action)="data">
          <svg @click="exportSheet(data.item.id)" v-if="(data.item.status == 'agreed' || data.item.status == 'canceled')" type="submit" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
            <path d="M20 12.5V6.8C20 5.11984 20 4.27976 19.673 3.63803C19.3854 3.07354 18.9265 2.6146 18.362 2.32698C17.7202 2 16.8802 2 15.2 2H8.8C7.11984 2 6.27976 2 5.63803 2.32698C5.07354 2.6146 4.6146 3.07354 4.32698 3.63803C4 4.27976 4 5.11984 4 6.8V17.2C4 18.8802 4 19.7202 4.32698 20.362C4.6146 20.9265 5.07354 21.3854 5.63803 21.673C6.27976 22 7.1198 22 8.79986 22H12.5M14 11H8M10 15H8M16 7H8M15 19L18 22M18 22L21 19M18 22V16" stroke="#3F8AE0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <svg @click="changeShowHistoryModal(data.item)" v-else-if="data.item.histories.length" type="submit" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
            <path d="M20 12.5V6.8C20 5.11984 20 4.27976 19.673 3.63803C19.3854 3.07354 18.9265 2.6146 18.362 2.32698C17.7202 2 16.8802 2 15.2 2H8.8C7.11984 2 6.27976 2 5.63803 2.32698C5.07354 2.6146 4.6146 3.07354 4.32698 3.63803C4 4.27976 4 5.11984 4 6.8V17.2C4 18.8802 4 19.7202 4.32698 20.362C4.6146 20.9265 5.07354 21.3854 5.63803 21.673C6.27976 22 7.1198 22 8.79986 22H12.5M14 11H8M10 15H8M16 7H8M15 19L18 22M18 22L21 19M18 22V16" stroke="#3F8AE0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>

import {mapState} from "vuex";
import axios from "axios";

export default {
  components: {
    purchaseModal: () => ({
      component: import("./editPurchaseModal"),
    }),
    purchaseHistoryModal: () => ({
      component: import("./purchaseHistoryModal"),
    }),
  },
  data () {
    return {
      currentPage: 1,
      perPage: 10,
      showModal: false,
      purchase: {},
      showHistoryModal: false,
      fields: [
        {
          key: "id",
          label: "Номер закупа",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "created_at",
          label: "Дата закупа",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "quantity",
          label: "Количество",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "amount",
          label: "Сумма",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "contractor",
          label: "Поставщик",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "status",
          label: "Статус",
          sortable: true,
          isRowHeader: true,
          class: "tb-all",
        },
        {
          key: "action",
          label: "",
          sortable: false,
          isRowHeader: true,
          class: "tb-all tb-xs",
        },
      ],
      statuses: {
        agreed: "table-btn__status",
        consideration: "table-btn__status-pending",
        agreed_chief: "table-btn__status-pending",
        agreed_analytic: "table-btn__status-pending",
        questioned: "table-btn__status-pending",
        answered: "table-btn__status-pending",
        canceled: 'table-btn__status-busy'
      }
    }
  },
  computed: {
    rows() {
      if (this.purchaseList) {
        this.$emit("spinner");
        return this.purchaseList
      }
      return 3;
    },
    ...mapState(["purchaseList", "textFilter", "dateFilter", "typeFilter"]),

  },
  async created() {
    if (this.$route.query.page != undefined && this.$route.query.page != 1) {
      this.currentPage = this.$route.query.page
    }
    await this.search();
  },
  methods: {
    status(value) {
      if (value === "agreed") {
        return "Согласован";
      }
      else if (value === "consideration") {
        return "Рассмотрение";
      }
      else if (value === "questioned") {
        return "Запрошена доп. информация";
      }
      else if (value === "agreed_chief") {
        return "Согласовано старшим механиком";
      }
      else if (value === "agreed_analytic") {
        return "Согласовано аналитиком";
      }
      else if (value === "answered") {
        return "Ответ";
      }
      else if (value === "canceled") {
        return "Отменен";
      }
    },
    changeShowModal (purchase) {
      this.showModal = !this.showModal
      this.purchase = structuredClone(purchase);
    },
    changeShowHistoryModal (purchase) {
      this.showHistoryModal = !this.showHistoryModal
      this.purchase = structuredClone(purchase);
    },
    async search(){
      await this.$store.dispatch("getPurchaseList", {
        page: this.currentPage,
        date: this.dateFilter,
        search: this.textFilter,
      })
    },
    changePage() {
      const searchURL = new URL(window.location);
      searchURL.searchParams.set('page', this.currentPage);
      window.history.pushState({}, '', searchURL)
    },
    async exportSheet(id){
      await axios({
        method: 'get',
        url : process.env.VUE_APP_API_URL + `/web/export-sheet?purchase_id=${id}`,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
        },
        responseType: 'blob'
      }).then(response => {
        const blob = new Blob([response.data], {
          type: 'application/pdf'
        });
        var url = window.URL.createObjectURL(blob)
        window.open(url);
      }).catch(function(error) {
        console.log(error)
      })
    }
  },
  watch: {
    async currentPage() {
      await this.search();
    },
    async textFilter() {
      this.currentPage = 1;
      await this.search();
    },
    async dateFilter() {
      this.currentPage = 1;
      await this.search();
    },
    async typeFilter() {
      this.currentPage = 1;
      await this.search();
    },
  },

}
</script>

<style scoped>
.table {
  width: 100%;
}
.pagination,
.pagination__text {
  position: absolute;
  top: -164px;
  right: -3px;
}
.pagination__text {
  right: 128px;
  top: -154px;
}
.pagination__btn-icon_prev {
  transform: rotate(180deg);
}
.table-btn__status-pending {
  display: block;
  padding: 8px 10px;
  height: auto;
  border-radius: 5px;
  background: #FFF4D2;
  color: #FF9E0D;
}
.table-btn__status-busy {
  display: block;
  padding: 8px 10px;
  height: auto;
  border-radius: 5px;
  background: #FBD2CE;
  color: #E74C3C;
}
</style>